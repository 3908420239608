<template>
  <q-layout view="hHh LpR lFr">
    <q-header elevated class="bg-primary text-white">
      <q-toolbar>
        <!-- <q-btn
          v-if="
            ['ORGANIZER', 'FILE_ORGANIZER', 'EDIT', 'COMMENT', 'VIEW'].indexOf(
              basicProfile['role']
            ) > -1
          "
          dense
          flat
          round
          icon="fas fa-bars"
          @click="leftDrawerOpen = !leftDrawerOpen"
        ></q-btn> -->
        <q-toolbar-title>
          <div class="row">
            <q-btn round>
              <q-avatar
                rounded
                class="glow animate__animated animate__pulse animate__infinite"
              >
                <img src="img/EscudoUNAMwhite.svg" />
              </q-avatar>
              <q-menu
                v-if="
                  [
                    'ORGANIZER',
                    'FILE_ORGANIZER',
                    'EDIT',
                    'COMMENT',
                    'VIEW',
                  ].indexOf(basicProfile['role']) > -1
                "
                transition-show="flip-right"
                transition-hide="rotate"
              >
                <main-menu></main-menu>
              </q-menu>
            </q-btn>
            <!-- <span class="q-ml-sm">Dirección de Servicios Tecnológicos</span> -->
            <!-- <span class="q-ma-none q-pa-none q-ml-sm q-pt-xs">SIDST</span> -->
            <span>{{ envType }}</span>
            <span>{{ envType2 }}</span>
          </div>
        </q-toolbar-title>
        <!-- <q-btn dense flat round icon="fas fa-bars" @click="toggleRightDrawer" /> -->
        <q-btn round v-if="basicProfile['imageUrl']">
          <q-avatar>
            <img :src="basicProfile['imageUrl']" />
          </q-avatar>
          <q-menu transition-show="flip-left" transition-hide="rotate">
            <div
              class="row justify-center items-center content-center q-pa-md"
              style="max-width: 250px"
            >
              <!-- <div class="column">
                <div class="text-h6 q-mb-md">Settings</div>
                <q-toggle v-model="mobileData" label="Use Mobile Data" />
                <q-toggle v-model="bluetooth" label="Bluetooth" />
              </div> -->

              <!-- <q-separator vertical inset class="q-mx-lg" /> -->

              <div class="row justify-center items-center content-center">
                <q-avatar class="text-center" size="72px">
                  <img :src="basicProfile['imageUrl']" />
                </q-avatar>

                <div class="col-12 text-subtitle1 q-mt-sm text-center">
                  {{ basicProfile["name"] }}
                </div>
                <div class="col-12 text-subtitle2 q-mb-sm text-center">
                  {{ basicProfile["email"] }}
                </div>

                <q-btn
                  class="col-12"
                  no-caps
                  color="primary"
                  label="Salir"
                  push
                  size="sm"
                  v-close-popup
                  @click="handleSignoutClick"
                ></q-btn>
              </div>
            </div>
          </q-menu>
        </q-btn>
      </q-toolbar>
    </q-header>
    <!-- <q-drawer
      show-if-above
      v-model="leftDrawerOpen"
      side="left"
      behavior="default"
      elevated
      content-class=""
    >
      <left-drawer-menu></left-drawer-menu>
    </q-drawer> -->
    <!-- <q-drawer
      v-model="rightDrawerOpen"
      side="right"
      behavior="mobile"
      elevated
      content-class="bg-grey-2"
    >
    </q-drawer> -->
    <q-page-container>
      <page-container></page-container>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import {
  defineComponent,
  defineAsyncComponent,
  // ref,
  onMounted,
} from "vue";
import { basicProfile, handleSignoutClick } from "../../../api/gapi/gapi";

export default defineComponent({
  name: "MainLayout",
  components: {
    // MarqueeText: defineAsyncComponent(() => import("@/components/MarqueeText.vue"))
    PageContainer: defineAsyncComponent(
      () => import("../components/PageContainer.vue")
    ),
    MainMenu: defineAsyncComponent(() => import("../components/MainMenu.vue")),
  },
  setup() {
    console.log("MainLayout ready");
    // handleClientLoad();
    // const leftDrawerOpen = ref(false);
    // const rightDrawerOpen = ref(false);
    onMounted(() => {
      // console.log(root.value);
    });
    return {
      basicProfile,
      handleSignoutClick,
      // leftDrawerOpen,
      // toggleLeftDrawer() {
      //   leftDrawerOpen.value = !leftDrawerOpen.value;
      // },
      // rightDrawerOpen,
      // toggleRightDrawer() {
      //   rightDrawerOpen.value = !rightDrawerOpen.value;
      // },
      envType: process.env.NODE_ENV,
      envType2: process.env.MY_ENV,
    };
  },
});
</script>

<style lang="scss">
.q-header {
  // background-color: rgba(0, 27, 88,0.5);
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
}
.q-drawer {
  background: rgba(255, 255, 255, 0.7) !important;
}
.glow {
  --animate-duration: 2.5s;
}
</style>
