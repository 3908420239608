import { render } from "./MainLayout.vue?vue&type=template&id=c4c9dc0a"
import script from "./MainLayout.vue?vue&type=script&lang=ts"
export * from "./MainLayout.vue?vue&type=script&lang=ts"

import "./MainLayout.vue?vue&type=style&index=0&id=c4c9dc0a&lang=scss"
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QAvatar,QMenu,QToggle,QSeparator,QDrawer,QPageContainer});qInstall(script, 'directives', {ClosePopup});
